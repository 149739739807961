.reviewTypeDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin: 12px 0;
    animation: createBox .25s;

}
@keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
.reviewTypeDiv svg {
    cursor: pointer;
    transition: cubic-bezier(1,-0.75, 1, 1) 0.5s;
}

.reviewOptionDiv {
    display: flex;
    margin: 24px 48px;
}

.reviewOptionDiv input {
    margin-right: 16px;
    margin-top: 0;
    pointer-events: none;
    height: 24px;
    width: 24px;
    padding: 0;
}

.reviewOptionDiv input {
    box-shadow: none !important;
    border-color: #51585e !important;
}

.reviewOptionDiv label {
    background-color: unset !important;
    color: unset !important;
    padding: 0;
    border: none !important;
    box-shadow: none !important;
}

.reviewOptionDiv label div {
    display: flex;
}

.reviewOptionDiv label div:hover {
    background-color: unset !important;
}

.btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle {
    background-color: unset !important;
    border: none !important;
    box-shadow: none !important;
}

.reviewSubmit {
    background-color: #1D4ED8;
    color:white;
    width: 425px;
    margin-top: 12px;
}