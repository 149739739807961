.coDiv {
    display: flex;
}

.cartCO {
    height: 100% !important;
}

.cartCO .cartFooter {
    bottom: 0;
}

.cartButtonCO {
    margin-top: 48px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: ease 0.5s;
    cursor: pointer;
    padding: 10px 16px;
    background-color: #E9ECEF;
}

.cartButtonCO svg {
    margin-right: 11.5px;
    height: 22.5px;
  }

/** START LEFT DIV **/

.leftDiv {
    width: 140%;
    padding: 2em 10vw;
}

.leftTitle {
    font-size: 4em;
    font-weight: 600;
    text-align: center;
}

.backToMenu {
    text-align: center;
    margin-bottom: 0.5em;
    cursor: pointer;
}

.deliveryChoiceDiv {
    margin: auto;
    background-color: #E9ECEF;
    border-radius: 35px;
    height: 4em;
    width: 20em;
    display: flex;
}

.deliveryChoiceDiv p {
    padding: 1em 2em;
    margin: auto;
    color: black;
    z-index: 1;
    cursor: pointer;
}

.selected {
    background-color: white;
    margin: auto;
    border-radius: 35px;
    height: 3.4em;
    width: 9.5em;
    transition: 0.3s;
}

.marker {
    fill: black;
}

/* SHARED CSS */

.locationDiv {
    margin-bottom: 10px;
}

.locationDiv, .paymentDiv {
    margin-bottom: 64px;
}

.locationTitle, .paymentTitle {
    font-size: 2.5em;
}

.modalTitle {
    margin: 32px auto;
    font-size: 24px;
    font-weight: 400 !important;
    font-family: 'Sanchez' !important;
    padding: 0 1em;
    text-align: center;
}

.modalTitle svg {
    cursor: pointer;
}

.modalTitle svg path {
    transition: 0.5s;
}

label {
    color: white;
}

input {
    border-radius: 5px;
    padding: 0 16px;
    font-size: 16px;
    outline: none;
    border: 2px solid #ADB5BD;
    background-color: white;
    color: black;
}

.modalSubmit {
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    display: flex;
    transition: 0.5s;
    background-color: #8BC24A;
}

.modalSubmit p {
    margin: 8px auto;
}

.modalSubmit:hover {
    background-color: #444;
}


/* LOCATION */

.addressDiv {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    height: 40px;
}

.address {
    margin: auto 0;
    font-weight: 500;
}


/* PAYMENT */

.paymentInfoDiv {
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.payment {
    margin: auto 0;
    font-weight: 500;
    font-family: "Inter";
}

.paymentDiv1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
}

.paymentDiv1 input {
    height: 40px;
}

.paymentDiv2 {
    height: 40px;
}

.paymentDiv1 label, .paymentDiv2 label {
    color: black;
}

.numberLabel {
    width: 30%;
}

.numberInput {
    width: 70%;
}

.expLabel {
    width: 30%;
    white-space: nowrap;
}
.expInput {
    width: 80px;
}

.cvvInput {
    width: 80px;
}


/** START RIGHT DIV **/

.rightDiv {
    background-color: #E9ECEF;
    width: 100%;
    min-height: 100vh;
    padding: 4em 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* TOTAL */

.coTotalDiv {
    padding: 2em 0;
}

.coTotalSubDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}

.coTotalSubDiv p {
    margin: 0;
    font-size: 1.2em;
}

.tip {
    display: flex;
}

.tipEdit {
    border-radius: 35px;
    padding: 3px 10px;
    cursor: pointer;
    transition: 0.2s;
    margin-right: 0.2em;
    border: 2px solid #ADB5BD;
}

.tipEdit:hover {
    border-color: #B54673;
}

.tipChosen {
    border-color: #B54673;
}

.coTotal {
    border-top: 1px solid grey; 
    padding-top: 1em;
}

.coTotal p {
    font-size: 1.5em;
}

.modalTipDiv {
    display: flex;
    justify-content: space-evenly;
    margin: 0.5em 0;
    padding-right: 50%;
}

.modalTipPriceDiv {
    display: flex;
    justify-content: space-between;
    padding: 0 2em;
    font-size: 1.5em;
}

.modalTipPriceDiv p {
    margin: auto 0;
}

.modalTipTotal {
    border-top: 1px solid grey;
    padding: 16px;
    padding-bottom: 0;
    margin: 32px;
    font-size: 1.8em;
}

.fixed, .percent {
    color: #E9ECEF;
    cursor: pointer;
    font-size: 2em;
    padding-bottom: 0.1em;
}

.tipFalse {
    cursor: unset !important;
    color: black;
}

.tipInput {
    font-size: 1.5em;
    width: 50%;
    height: 1.5em;
    margin: auto;
}


/* SUBMIT ORDER */

.coSubmitOrder {
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    display: flex;
    transition: 0.5s;
    align-items: center;
    justify-content: center;
    height: 42px;
    background-color: #8BC24A;
}

.coSubmitOrder p {
    font-weight: 600;
    margin: 0;
}


/* MEDIA QUERIES */

@media only screen and (max-width: 1000px) {
    .leftDiv {
        width: 100%;
    }

    .rightDiv {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .coDiv {
        display: contents;
    }

    .leftDiv, .rightDiv {
        padding: 32px;
    }

    .rightDiv {
        min-height: 0;
    }
}

@media only screen and (max-width: 370px) {
    .tip div {
      font-size: 0.8em;
      margin: auto;
    }
  
    .leftDiv {
      padding: 1em 0 !important;
    }
}


/* WEBKIT */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}