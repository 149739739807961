html {
  overflow: hidden;
}

*:not(input) {    
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html, body, #root, .pageContainer {
  height: 100%;
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: fixed;
  top: 0;
  background-color: #1e2022;
}

@media only screen and (max-width: 600px) {
  html, body, #root, .pageContainer {
    height: calc(100%);
  }
}


/* CONCEPT CHOICE */

.concepts {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
}

.concepts div {
  display: flex;
}

.singleConceptLogo {
  height: 60px;
  float: left;
}

.conceptDiv {
  height: 100%;
  font-size: 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.1s;
  margin-top: 8px;
}

.conceptDiv ~ .conceptDiv {
  margin-left: 10px;
}

.conceptImgDiv {
  border-radius: 8px;
  overflow: hidden;
  height: 75px;
  width: 75px;
  margin-bottom: 3px;
}

.conceptImgDiv img {
  width: 75px;
  height: 75px;
}

.selectedConcept div {
  outline: 3px solid #1D4ED8;
}


/* MENU TITLE */

.menuTitle {
  font-size: 2.5em;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}

.menuTitleDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 13.5vw 16px;
}


/* MENU FILTER */

.menuFilterContainer {
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 0 0 auto;
  padding: 0 calc(4vw + 20px);
}

.menuFilterDiv {
  display: flex;
  padding: 16px 0;
  margin: auto;
}

.menuFilterDiv div {
  display: flex;
  align-items: center;
}

.popover {
  background-color: transparent !important;
}

.popover-header {
  color: white;
}

@media only screen and (max-width: 600px) {
  .menuFilterDiv {
    padding: 16px 0 0;
  }
}


/* MENU NAV */

.menuNavDiv {
  display: flex;
  height: 44px;
}


/* SEARCH */

.searchBar {
  display: flex;
  align-items: center;
}

.searchBar svg {
  height: 20px;
  transform: translate(35px, -2px);
  cursor: pointer;
}

.searchBar input {
  height: 100%;
  width: 250px;
  border: 1px solid #CED4DA;
  border-radius: 4px;
  padding-left: 48px;
  outline: none;
}


/* LOGIN */

.loginButton {
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: ease 0.5s;
  cursor: pointer;
  padding: 10px 16px;
  margin: 0 24px;
}

.loginButton svg {
  margin-right: 11.5px;
  height: 18px;
}

.loginInputDiv {
  display: flex;
  justify-content: center;
  margin: 2em 0;
}

.loginInputDiv input {
  width: 1.4em;
  font-size: 3em;
  text-align: center;
  border-radius: 5px;
  padding: 0;
  outline-color: white;
  border: 2px solid #ADB5BD;
}

.loginInputDiv input ~ input {
  margin-left: 0.1em;
}

.phoneDiv {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  margin: 40px 0 72px;
  height: 40px;
}

.phoneDiv input {
  border-radius: 5px;
  padding: 0 16px;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: 2px solid #ADB5BD;
  background-color: white;
  color: black;
}

.phoneDiv select {
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  margin-right: 2em;
}

.phoneDiv div {
  margin-right: 20px;
}

.phoneDiv div button {
  width: 100%;
  height: 100%;
  border: 2px solid #ADB5BD;
  background-color: white;
  background-color: white !important;
  box-shadow: none !important;
  color: black;
  padding-right: 16px;
  padding-left: 16px;
}

.btn-check:focus+.btn-primary, .btn-primary:focus, .show>.btn-primary.dropdown-toggle, .btn-primary:hover {
  color: black !important;
  background-color: white !important;
  border-color: #ADB5BD !important;
}

.dropdown-menu {
  max-height: 100px !important;
  overflow-y: scroll;
  width: unset !important;
}

.dropdown-menu::-webkit-scrollbar-track {
  background: white;
}


/* ACCOUNT */

.dataDiv {
  display: flex;
  justify-content: space-between;
  padding: 0 1.35em;
  font-size: 1.4em;
  margin-bottom: 1em;
}

.dataDiv div {
  margin: auto 0;
}

.dataDiv input {
  width: 60%;
  height: 90%;
  outline: none;
  padding: 0.5em;
  border-radius: 5px;
}


/* CART BUTTON */

.cartButton {
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: ease 0.5s;
  cursor: pointer;
  padding: 10px 16px;
}

.cartButton svg {
  margin-right: 11.5px;
  height: 22.5px;
}


/* BOTTOMBAR */

.bottomBarDiv {
  display: none;
  justify-content: space-evenly;
  width: 100%;
  height: 65px;
  z-index: 1060;
}

.bottomBarButton {
  text-align: center;
  height: 100%;
  width: 60px;
  margin: 2px 0 8px;
}

.bottomBarButton svg {
  height: 2.5em;
  transform: translate(0, 5px);
  margin: auto;
}

.bottomBarButton p {
  margin: 0;
  margin-top: 2px;
  font-weight: 600;
}

.iconSvg {
  height: 2.5em;
  display: flex;
  align-content: center;
  margin-top: 2px;
}


@media only screen and (max-width: 600px) {
  .bottomBarDiv {
    display: flex;
  }

  .cartNotMobile {
    display: none;
    margin-left: 1em;
  }

  .modal-dialog-centered {
    align-items: unset !important;
  }

  .cartBack {
    display: none !important;
  }
}


/* SHARE */

.shareDiv {
  padding: 12px;
  z-index: 1040;
  background-color: #F8F9FA;
}

@media only screen and (max-width: 600px) {
  .shareDiv {
    z-index: 1051;
  }
}


/* NAVBAR */

.navBarContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  float: left;
  width: 30vw;
  scroll-behavior: smooth;
  padding-left: 13.5vw;
  margin-top: 64px;
}

.navBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 100%;
}

.backToTop {
  position: fixed;
  bottom: 1em;
  right: 1em;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
  z-index: 1;
}

.backToTop svg path {
  transition: 0.5s;
}

.backToTopSvg {
  height: 2.5em;
}

.navBarLink {
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  margin: 3px 0;
  transition: 0.3s ease-in;
  font-size: 20px;
}

.active {
  text-decoration: overline;
}

@media only screen and (max-width: 600px) {
  .navBarContainer {
    display: flex;
    float: unset;
    width: unset;
    margin: 0;
    overflow-y: unset;
    overflow-x: scroll;
    min-width: 100vw;
  }

  .navBar {
    display: flex;
    flex-direction: unset;
    padding: 20px;
    text-align: center;
  }

  .navBarNoCart {
    top: 8.75em !important;
  }

  .navBarLink {
    font-size: unset;
  }

  .navBarLink ~ .navBarLink {
    margin-left: 18px;
  }

  .lastLink {
    padding-right: 10vw;
  }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
  .navBarContainer {
    float: unset;
    height: unset;
    width: unset;
    margin-left: unset;
    top: 20.5% !important;
    overflow-y: unset;
    overflow-x: scroll;
    min-width: 100%;
    padding-left: 0 !important;
    margin-top: 0;
  }

  .navBar {
    display: flex;
    flex-direction: unset;
    justify-content: space-between;
    padding: 1.4em;
    padding-bottom: 1em;
    text-align: center;
  }

  .navBarLink {
    font-size: unset;
  }

  .navBarLink ~ .navBarLink {
    margin-left: 18px;
  }
}


/* MENU ITEMS */

.pageContainer {
  display: flex;
  flex-direction: column;
}

.menuContainer {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .menuContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.sectionContainer {
  overflow: scroll;
  height: 100%;
  scroll-behavior: smooth;
}

.section {
  margin: 0 !important;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 700;
  margin-top: 64px;
}

.sectionDesc {
  font-size: 1.1em;
  padding-right: 12.5vw;
}

.selectionItems {
  padding-right: 12.5vw;
}

.outOfStock div {
  opacity: 0.7;
  cursor: unset;
}

.menuItemContainer {
  flex: 0 0 33.3% !important;
  padding: 8px !important;
}

.menuItem {
  height: 100%;
  cursor: pointer;
  justify-content: center;
  transition: 0.5s;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px #00000014;
}

.readOnly {
  pointer-events: none;
}

.itemImgDivDiv {
  overflow: hidden;
  height: 217px;
}

.itemImgDiv {
  border-radius: 10px 10px 0 0;
  transition: 0.5s !important; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.menuItem:hover > .itemImgDivDiv > .itemImgDiv {
  transform: scale(1.15, 1.15);
}

.menuItemInfoDiv {
  padding: 16px 24px;
  /*height: 141px;*/
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  width: 100%;
}

.itemDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

.itemName {
  margin: auto 0.5em auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemBadge {
  margin: 0.3em 0 auto auto !important;
}

.itemPrice {
  margin: auto 0 auto auto;
  font-size: 0.8em;
}

.noResultsDiv {
  font-size: 2em;
  margin-top: 2em !important;
  text-align: center;
}

.noResultsDiv p {
  margin: auto;
}

@media only screen and (max-width: 1600px) {
  .menuItemContainer {
      flex: 0 0 33.3% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .menuItemContainer {
      flex: 0 0 50% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .menuItemContainer {
      flex: 0 0 50% !important;
  }
}

@media only screen and (max-width: 800px) {
  .menuItemContainer {
      flex: 0 0 100% !important;
  }

  .menuItem:hover > .itemImgDiv {
    background-size: 110% auto;
  }
}


/* MENU ITEMS (WIDTH < 600px) */

.menuItemMobile {
  flex: 0 0 100% !important;
  padding: 5px 4em !important;
  text-align: center;
} 

.selectionItemMobile {
  padding: 20px 20px !important;
  cursor: pointer;
}

.itemImgMobile {
  height: 7em;
  margin-left: 1em;
  float: right;
}

.itemNameMobile {
  font-size: 1.2em;
  margin-bottom: 4px;
}

.itemDietDivMobile {
  font-size: 0.8em;
  margin-bottom: 4px;
}

.itemPriceDivMobile, .itemDietDivMobile {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.itemPriceMobile {
  margin-bottom: 0;
  margin-right: 8px;
  font-size: 14px;
}

.itemDietMobile {
  margin-right: 8px;
}

.itemDietMobile ~ .itemDietMobile {
  margin-left: 0.5em;
}

.itemDietMobile img {
  height: 10px;
}

.itemDescMobile {
  margin-bottom: 0;
  /*max-height: 100px;*/
  /*overflow: hidden;*/
  /*display: -webkit-box;*/
  /*-webkit-line-clamp: 2;*/
  -webkit-box-orient: vertical;
  font-size: 14px;
  margin: 0;
  margin-bottom: 8px;
}

@media only screen and (max-width: 600px) {
  .itemPriceMobile {
    margin: 0 4px;
  }
  
  .itemDietMobile {
    margin: 0 4px;
  }
}

@media only screen and (max-width: 370px) {
  .itemImgMobile {
    display: none;
  }
}


/* MODAL GLOBAL */

.modal-body {
  scroll-behavior: smooth;
}

.modal-dialog {
  max-width: none !important;
  width: 785px !important;
}

.modal-content {
  overflow: hidden;
  border-radius: 10px !important;
}

.modal-backdrop {
  opacity: 0.7 !important;
}

.qrModal {
  justify-content: center;
  width: unset !important;
  height: unset !important;
  background-color: none !important;
}

.qrModal .modal-content {
  width: unset !important;
  height: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 560px) {
  .qrModalRightDiv {
    margin-right: 1rem;
  }
}


/* ITEM POP-UP MODAL */

.modalX {
  cursor: pointer;
  position: sticky;
  height: 0;
  top: 1em;
  right: 1em;
  z-index: 3;
}

path {
  transition: 0.5s;
}

.modalX svg {
  height: 20px;
  margin-right: calc(100% - 61px);
  margin-left: 20px;
}

.itemModalBody {
  max-height: 80vh !important;
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 32px !important;
}

.itemModalImgDiv {
  padding-bottom: 16px;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.itemModalImgDiv img {
  height: 150px;
}

.itemModalImgDivPlaceHolder {
  height: 50px;
  width: 100%;
}

.itemModalName {
  font-size: 24px;
  font-family: "Sanchez";
}

.itemModalDesc {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.itemModalOptionsDiv {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #CED4DA;
  margin: 16px 32px;
}

.itemModalOptionDiv {
  flex: 0 0 50%;
  height: 32px;
  padding: 8px 0;
  margin: 8px 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .itemModalOptionDiv {
    flex: 0 0 100%;
  }
}

.accordion-item {
  border: none !important;
}

.accordion-body {
  display: flex;
  flex-wrap: wrap;
}

.notLastMod {
  border-bottom: 2px solid #CED4DA !important;
}

.accordion-header {
  background-color: #F8F9FA !important;
}

.accordion-button {
  border: 1px solid #CED4DA;
}

.accordion-button:focus, .accordion-button:not(.collapsed) {
  box-shadow: none !important;
  color: unset !important;
  background-color: #F8F9FA !important;
  border: none !important;
}

.accordion-item:last-of-type, .accordion-item:first-of-type .accordion-button.collapsed {
  background-color: white !important;
  border-radius: 0 !important;
}

.modifierHeader {
  font-family: "Open Sans";
  font-weight: 600;
  margin-right: 16px;
  flex-shrink: 9999;
}

.modifierRequired {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
}

.modifierChoose {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: #68717A;
}

.itemModalOption {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  font-size: 14px !important;
  text-align: left !important;
}

.itemModalOption div {
  display: flex;
  align-items: center;
}

.itemModalOption div div {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.itemModalOption input {
  margin-right: 16px;
  margin-top: 0;
  pointer-events: none;
  height: 24px;
  width: 24px;
  padding: 0;
}

.form-check-input:checked {
  border: none !important;
  box-shadow: none !important;
}

.modifierPrice {
  margin: auto 0 auto 1em;
  font-size: 16px;
}

.modifierQuantityDiv {
  display: flex;
  border: 2px solid #ADB5BD;
  border-radius: 4px;
  margin-right: 24px;
}

.modifierMinus, .modifierPlus {
  margin: auto;
  cursor: pointer;
  flex: 0 0 33.3%;
  width: 29px !important; 
}

.modifierQuantity {
  font-size: 14px;
  line-height: 10%;
  flex: 0 0 33.3%;
  margin: auto;
  margin-bottom: auto;
}

.itemModalFooter {
  padding: 24px 32px;
  z-index: 1;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
  margin: auto 0 0 0;
}

.itemModalFooterRemove {
  height: 4vh;
  margin: 0 0 1em 0;
  border-radius: 5px;
  text-align: center;
  display: flex;
  transition: 0.2s;
  cursor: pointer;
}

.itemModalFooterRemove p {
  margin: auto;
}

.itemModalFooterDiv {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.itemModalQuantityDiv {
  display: flex;
  border-radius: 8px;
  text-align: center;
  width: 30%;
  margin-right: 1em;
  border: 2px solid #ADB5BD;
}

.itemModalMinus, .itemModalPlus {
  font-size: 18px;
  margin: auto;
  cursor: pointer;
  flex: 0 0 33.3%;
}

.itemModalQuantity {
  font-size: 18px;
  line-height: 10%;
  padding-bottom: 0.1em;
  flex: 0 0 33.3%;
  margin: auto;
  margin-bottom: auto;
}

.itemModalAddToCart {
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  transition: 0.5s;
}

.itemModalAddToCart p, .itemModalAddToCartNot p {
  margin: 8px auto;
}

.itemModalAddToCartNot {
  border-radius: 8px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  transition: 0.5s;
}


/* CART OFFCANVAS */

.cart {
  border-top-left-radius: 15px;
  width: 880px !important;
}

.cartPastOrders {
  width: 100%;
  margin: 24px;
}

@media only screen and (max-width: 880px) {
  .cart {
    border-left: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .cart {
    height: 98%;
  }
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  border-bottom: 1px solid #DEE2E6;
  margin: 32px 9px 0 24px;
  line-height: 27px;
  padding-bottom: 16px;
}

.cartHeader div ~ div {
  padding: 0;
  margin-right: 23px;
}

.cartItemsContainer {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 16.5px;
}

.cartNoItems {
  text-align: center;
  padding: 1em;
}

.cartItemDiv {
  display: flex;
  justify-content: space-between;
  padding: 16px 7.5px;
}

.cartItemDiv ~ .cartItemDiv {
  border-top: 1px solid #DEE2E6;
}

.cartItem {
  width: 100%;
  padding-left: 15px;
}

.cartItemImgDiv {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartItemImgDiv div {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.cartItemImgDiv div img {
  height: 50px;
  cursor: pointer;
}

.cartItemHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.cartItemName {
  text-decoration: underline;
  cursor: pointer;
  font-family: "Sanchez";
  font-weight: 600;
}

.cartPrice {
  font-weight: 600;
}

.cartItemModsDiv {
  font-size: 14px;
}

.cartItemMod {
  display: flex;
  justify-content: space-between;
}

.cartItemDelete {
  display: flex;
  align-items: center;
}

.cartItemDelete svg {
  height: 20px;
}

.cartItemDelete svg path {
  fill: grey;
  transition: 0.5s;
  cursor: pointer;
}

.cartFooter {
  width: 100%;
  padding: 32px 16.5px;
  background: white;
  border-radius: 8px 8px 0 0;
}

.cartTimeDiv {
  border-bottom: 1px solid #DEE2E6;
  padding: 0 7.5px 24px 7.5px;
}

.cartTime {
  font-size: 14px;
}

.cartTimeTitleDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.cartTimeEdit {
  text-decoration-line: underline;
  font-size: 12px;
  cursor: pointer;
}

.cartAddonsDiv {
  border-bottom: 1px solid #DEE2E6;
  padding: 24px 7.5px;
}

.cartAddonsTitle {
  margin-bottom: 6px;
}

.cartAddonsContainer {
  overflow-x: scroll;
}

.cartAddons {
  display: flex;
}

.cartAddon {
  display: flex;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  padding: 0 16px;
  align-items: center;
  line-height: 19px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.react-datepicker-wrapper {
  padding: 1px !important;
  margin: 6px 0 6px 8px;
  border: 1px solid #DEE2E6;
  background-color: #DEE2E6;
  border-radius: 4px;
  transition: 0.5s;
}

.react-datepicker-wrapper:hover {
  background-color: white;
}

.react-datepicker__input-container {

  margin: 0 !important;
}

.react-datepicker__input-container div {
  margin: 0 !important;
}

.react-datepicker {
  margin: 0 !important;
}

.cartAddon ~ .cartAddon {
  margin-left: 8px;
}

.cartAddonDiv {
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0;
}

.cartAddon input {
  margin-right: 8px;
}

.cartSubtotalDiv {
  display: flex;
  justify-content: space-between;
  padding: 0 7.5px;
}

.cartSubtotalDiv div {
  font-size: 20px;
  font-weight: 600;
  margin: 24px 0 16px 0;
}

.cartCheckoutDiv {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  height: 50px;
  padding: 0 7.5px;
}

.cartClear {
  width: 100%;
  min-width: 100px;
  height: 100%;
  padding: 12.5px 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.cartCheckout {
  width: 400%;
  min-width: 100px;
  height: 100%;
  padding: 12.5px 16px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartUpdateButton {
  width: 50%;
  background-color: grey;
  height: 100%;
  border-radius: 8px;
  margin-left: 10px;
  cursor: pointer;
}

.cartUpdateButton svg {
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .cartItemsContainer {
    height: 42.5%;
  }
}

@media only screen and (max-width: 400px) {
  .cartItemsContainer {
    height: 35%;
  }

  .cartClear, .cartCheckout {
    width: 50% !important;
  }
}

.noCartItems {
  cursor: unset;
}


/* MEDIA QUERIES */

@media only screen and (max-width: 1250px) {
  html body {
    font-size: 85%;
  }

  .itemImg {
    height: 5em;
  }
}

@media only screen and (max-width: 1000px) {
  .menuTitleDiv {
    padding: 15px 4.5vw 16px;
  }

  .navBarContainer {
    padding-left: 4vw;
  }

  .selectionItems {
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 800px) {
  .searchBar input {
    width: 150px;
  }

  .modal-dialog {
    width: 100vw !important;
    height: 100vh !important;
    transform: none !important;
    margin: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .menuTitleDiv {
    justify-content: center;
  }

  .sectionTitle {
    text-align: center;
  }
  
  .sectionDesc {
    text-align: center;
  }

  .selectionItems {
    padding: 0 10px !important;
  }

  .itemPriceDivMobile, .itemDietDivMobile {
    justify-content: center !important;
  }

  .modal-content {
    height: 93% !important;
    border-radius: unset !important;
    border: unset !important
  }

  .itemModalBody {
    max-height: unset !important;
  }

  .cartFooter {
    bottom: 4.5em;
  }
}

@media only screen and (max-width: 400px) {
  .cartQuantityDiv {
    display: none;
  }

  .cartPrice {
    width: 100%;
  }

  .cartItemHeader {
    padding: 0.5em 0;
  }

  .cartItemModsDiv {
    margin: 0 calc(12.5% + 2em) 0 2em;
    width: unset;
  }

  .itemModalFooterDiv {
    font-size: 0.7em;
  }

  .itemModalName {
    margin-top: 2em;
  }
}


/* WEBKIT */

::-webkit-scrollbar {
  width: 0.5em !important;
  height: 0.5em !important;
  overflow: overlay;
}

::-webkit-scrollbar-track {
  background: transparent;
}
 
::-webkit-scrollbar-thumb {
  border-radius: 35px;
  background: #555; 
}

::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    height: 0 !important;
  }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
  ::-webkit-scrollbar {
    height: 0 !important;
  }
}