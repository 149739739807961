.tContainer1 {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.tContainer2 {
    
}

.tContainer3 {
    border-radius: 4px;
    border: 1px solid #e1e5e9;
    margin-bottom: 12px;
}

.tCheckInput {
    cursor: pointer;
    margin: auto;
    width: 24px;
    height: 24px;
}


.paymentInfoDiv .dropdown-toggle, .paymentInfoDiv .dropdown, .paymentInfoDiv .dropdown-menu {
    width: 100% !important;
}

.dropdown-menu {
    max-height: unset !important;
}

.btn-group {
    justify-content: center;
}